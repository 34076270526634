<template>
  <div>
    <div class="profile-wrapper-main">
      <div class="container-main profile_main_mobile">
        <h1 class="main-title-page" @click="goToProfile">Профиль</h1>
        <div v-if="section_id === 'main'" class="profile_section_name">{{ $t('Main') }}</div>
        <div v-if="section_id ==='password'" class="profile_section_name">{{ $t('Reset_password') }}</div>
        <div v-if="section_id ==='settings'" class="profile_section_name">{{ $t('Notification_settings') }}</div>
      </div>
    </div>
    <div class="container-main position-relative profile-container">
      <div v-if="section_id === 'main'">
        <div class="profile-inputs-wrapper-mobile">
          <div class="profile-inputs-column-mobile">
            <div class="smart-oqu-group-theme">
              <label class="smart-oqu-label-theme" for="iin">{{ $t('IIN') }}</label>
              <input
                disabled
                v-model="user.iin"
                autocomplete="false"
                class="smart-oqu-input-theme"
                type="text"
                placeholder="ИИН"
                id="iin">
            </div>
            <div class="smart-oqu-group-theme">
              <label class="smart-oqu-label-theme" for="email">{{ $t('Email') }}</label>
              <input
                v-model="user.email"
                autocomplete="false"
                class="smart-oqu-input-theme"
                type="text"
                placeholder="Электронная почта"
                id="email">
            </div>
            <div class="smart-oqu-group-theme">
              <label class="smart-oqu-label-theme" for="phone">{{ $t('Mobile_phone') }}</label>
              <input
                v-mask="'+7 (###) ### ##-##'"
                v-model="user.phone"
                autocomplete="false"
                class="smart-oqu-input-theme"
                type="text"
                placeholder="+7 (___) ___ __-__"
                id="phone">
            </div>
          </div>
          <div class="profile-inputs-column-mobile">
            <div class="smart-oqu-group-theme">
              <label class="smart-oqu-label-theme" for="parent">{{ $t('Name_of_parent') }}</label>
              <input
                v-model="user.parent_name"
                autocomplete="false"
                class="smart-oqu-input-theme"
                type="text"
                placeholder="ФИО родителя (опекуна)"
                id="parent">
            </div>
            <div class="smart-oqu-group-theme">
              <label class="smart-oqu-label-theme" for="phoneParent">{{ $t("Parent's_mobile_phone") }}</label>
              <input
                v-mask="'+7 (###) ### ##-##'"
                v-model="user.parent_phone"
                autocomplete="false"
                class="smart-oqu-input-theme"
                type="text"
                placeholder="+7 (___) ___ __-__"
                id="phoneParent">
            </div>
          </div>
        </div>
        <div class="profile-update">{{ $t('Update_Profile') }}</div>

      </div>
      <div v-if="section_id ==='password'">
        <div class="profile-inputs-wrapper-mobile">
          <div class="profile-inputs-column-mobile profile-margin">
            <div class="password-required-name">{{ $t('Your_password_must') }}:</div>

            <div class="recommendation-block">
              {{ $t('Consist_of_more_than_8_characters') }}
            </div>
            <div class="recommendation-block">
              {{ $t('Contain_at_least_one_capital_letter') }}
            </div>
            <div class="recommendation-block">
              {{ $t('Contain_at_least_one_number') }}
            </div>
            <div class="recommendation-block">
              {{ $t('Contain_special_characters') }}
            </div>

          </div>
          <div class="profile-inputs-column-mobile profile-margin">
            <div class="strong-password-recommendation">{{ $t('Choose_security_password') }}
              <div class="lock-key-profile"></div>
            </div>
            <div class="smart-oqu-group-theme">
              <label class="smart-oqu-label-theme" for="password">Новый пароль</label>
              <input autocomplete="false" class="smart-oqu-input-theme" type="text" name=""
                     placeholder="Новый пароль"
                     id="password">
            </div>
            <div class="smart-oqu-group-theme">
              <label class="smart-oqu-label-theme" for="password-repeat">Повторите пароль</label>
              <input autocomplete="false" class="smart-oqu-input-theme" type="text" name=""
                     placeholder="Повторите пароль" id="password-repeat">
            </div>
            <div class="profile-update password-update">Обновить профиль</div>
          </div>
        </div>


      </div>
      <div v-if="section_id ==='settings'">
        <div class="profile-inputs-wrapper-mobile">
          <div class="profile-inputs-column-mobile profile-margin">
            <div class="strong-password-recommendation">Допуск уведомлений
              <div class="lock-key-profile"></div>
            </div>
            <div class="switch-form-main">
              <div>Системные (личный кабинет)</div>
              <div><label class="form-switch">
                <input type="checkbox">
                <i></i>
              </label></div>
            </div>
            <!--            <div class="switch-form-main">-->
            <!--              <div>СМС уведомления</div>-->
            <!--              <div><label class="form-switch">-->
            <!--                <input type="checkbox">-->
            <!--                <i></i>-->
            <!--              </label></div>-->
            <!--            </div>-->
            <div class="switch-form-main">
              <div>Электронная почта</div>
              <div><label class="form-switch">
                <input type="checkbox">
                <i></i>
              </label></div>
            </div>
          </div>
          <div class="profile-inputs-column-mobile profile-margin">
            <div style="margin-top: 0" class="smart-oqu-group-theme">
              <label class="smart-oqu-label-theme" for="lang">Язык уведомлений</label>
              <select autocomplete="false" class="smart-oqu-input-theme" type="text" name=""
                      placeholder="Язык уведомлений"
                      id="lang">
                <option>{{$t('ru')}}</option>
                <option>{{$t('kz')}}</option>
              </select>
            </div>

            <div class="notifications-images"></div>

          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import Profile from "@/models/profile";
import VueMask from "v-mask";
import Vue from "vue";

export default {
  name: "Profile_settings",
  data() {
    return {
      currentTab: 'main',
      profile: new Profile({}),
      avatar: null,
      section_id: '',
      user: {}
    }
  },
  methods: {
    setSectionId() {
      this.section_id = this.$route.params.section
    },
    goToProfile() {
      this.$router.push(`/profile`)
    }
  },
  mounted() {
    this.setSectionId()
    if (localStorage.getItem('user')) {
      const userData = JSON.parse(localStorage.getItem('user'))
      this.user = userData
    }
  },

  beforeCreate() {
    Vue.use(VueMask);
  },
}
</script>

<style scoped>

</style>
